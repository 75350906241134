import React, {useEffect} from "react";
import {KApp, KErrorPage, KStandardFooter} from "@kopjra/uikit";
import Toolbar from "../containers/Toolbar";
import {Route, Routes} from "react-router-dom";
import {ProtectedRoute, Routes as R} from "../utils/router";
import Home from "../containers/Home";
import {Translate} from "react-redux-i18n";
import UserProfile from "../containers/UserProfile";
import {useAuth0} from "@auth0/auth0-react";
import {initGetTokenFunction} from "../api";
import {BigWaiter} from "./BigWaiter";
import SolutionDetail from "../containers/SolutionDetail";
import Cart from "../containers/Cart";
import Payment from "../containers/Payment";
import UserBillingInfo from "../containers/UserBillingInfo";
import ManagePaymentMethods from "../containers/ManagePaymentMethods";
import Subscriptions from "../containers/Subscriptions";
import EmailVerified from "../containers/EmailVerified";
import Invoices from "../containers/Invoices";
import Admin from "../containers/Admin";
import GodMode from "../containers/GodMode";

export interface StateProps {
    locale: string;
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const App: React.FC<Props> = ({locale}) => {
    const {getAccessTokenSilently, isLoading} = useAuth0();
    useEffect(() => {
        initGetTokenFunction(getAccessTokenSilently);
    }, [getAccessTokenSilently]);

    useEffect(() => {
        $(document).on("click", ".iubenda-cs-preferences-link", (e) => {
            e.preventDefault();
            e.stopPropagation();
            (window as any)._iub.cs.ui.openPreferences(undefined, e);
        });
    },  []);
    return isLoading ? (
        <BigWaiter/>
    ) : (
        <KApp>
            <Toolbar/>
            <Routes>
                <Route path={R.HOME} element={<Home/>}/>
                <Route path={R.CART} element={<Cart/>}/>
                <Route path={R.PAYMENT} element={<ProtectedRoute element={Payment}/>}/>
                <Route path={R.USER_ACCOUNT} element={<ProtectedRoute element={UserProfile}/>}/>
                <Route path={R.USER_BILLING} element={<ProtectedRoute element={UserBillingInfo}/>}/>
                <Route path={R.USER_PAYMENT} element={<ProtectedRoute element={ManagePaymentMethods}/>}/>
                <Route path={R.USER_SUBSCRIPTIONS} element={<ProtectedRoute element={Subscriptions}/>}/>
                <Route path={R.USER_INVOICES} element={<ProtectedRoute element={Invoices}/>}/>
                <Route path={R.EMAIL_VERIFIED} element={<ProtectedRoute element={EmailVerified}/>}/>
                <Route path={R.SUPER_USE} element={<ProtectedRoute element={Admin} requiredClaims={["st:wf:manual"]}/>}/>
                <Route path={R.SUPER_ADMIN} element={<ProtectedRoute element={GodMode} requiredClaims={["st:admin", "god"]}/>}/>
                <Route path={R.SOLUTION_DETAIL()} element={<SolutionDetail/>}/>
                <Route path={R.ALL} element={<KErrorPage title={<Translate value="error.page"/>} code={"404"}
                                                         message={<Translate value="error.notFound"/>}/>}/>
            </Routes>
            <KStandardFooter/>
        </KApp>
    );
}
